$(function () {
  if (!window.gsap) {
    console.error("Lalilala Marquee needs GSAP to work");
    return;
  }

  function debounce(func) {
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(
        () => {
          func();
        },
        500,
        event
      );
    };
  }

  $("[lalilala-marquee]").each(function (i, marquee) {
    const durationFor1440Px =
      parseInt(marquee.getAttribute("lalilala-marquee"), 10) || 5;
    const marqueeContent = marquee.firstChild;
    if (!marqueeContent) {
      return;
    }

    const marqueeContentClone = marqueeContent.cloneNode(true);
    marquee.append(marqueeContentClone);

    let tween;
    const playMarquee = () => {
      let progress = tween ? tween.progress() : 0;
      tween && tween.progress(0).kill();
      const width = parseInt(
        getComputedStyle(marqueeContent).getPropertyValue("width"),
        10
      );
      const gap = parseInt(
        getComputedStyle(marqueeContent).getPropertyValue("column-gap"),
        10
      );
      const distanceToTranslate = -1 * (gap + width);
      const duration = (durationFor1440Px * -distanceToTranslate) / 1440;

      if (marquee.hasAttribute("lalilala-marquee-reverse")) {
        tween = gsap.fromTo(
          marquee.children,
          { x: distanceToTranslate },
          { x: 0, duration, ease: "none", repeat: -1 }
        );
      } else {
        tween = gsap.fromTo(
          marquee.children,
          { x: 0 },
          { x: distanceToTranslate, duration, ease: "none", repeat: -1 }
        );
      }
      tween.progress(progress);
    };
    playMarquee();

    if (marquee.hasAttribute("lalilala-marquee-pause-on-hover")) {
      $(marquee).hover(
        function () {
          if (tween) {
            tween.pause();
          }
        },
        function () {
          if (tween) {
            tween.resume();
          }
        }
      );
    }

    window.addEventListener("resize", debounce(playMarquee));
    $(marquee).imagesLoaded(playMarquee);
  });
});
